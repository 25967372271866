.simple-form {
  margin-top: 4em;
  margin-bottom: 2em;

  .form-box {
    background: #fff;
    padding: 2em;
    max-width: 480px;
    border: 1px solid #ccc;

    .btn {
      @include fontSize(12)
    }

    a {
      &.highlight-border {
        border: 1px solid $btn-default-border;
      }
    }
  }

  .required:after {
    content: " *";
    color: $red;
  }

  .has-error {
    input {
      border: 1px solid $red;
    }

    label {
      color: $red;
    }

    .help-block {
      color: $red;
    }
  }
}