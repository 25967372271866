body.homepage {
  background: #fff;

  #banner {
    margin-top: -20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    .transparent-overlay-dark {
      background: rgba(0, 0, 0, 0.5);
      min-height: 480px;

      @media (min-width: $screen-md-min) {
        min-height: 520px;
      }

      .strapline {
        margin-top: 3em;
        color: #fff;
      }
    }
  }
}
