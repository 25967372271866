.middle-box {
  max-width: 500px;
  z-index: 100;
  margin: 0 auto;
  padding: 40px 20px;
  height: 75vh;

  h1 {
    @include fontSize(150);
  }

  h3 {
    margin-bottom: 1em;
  }

  .home {
    margin-top: 2em;
  }
}