h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
}

dd, li, td, p, a, address {
  font-weight: 300;

  @media print {
    font-size: 12pt !important;
  }
}