html {
  background: $label-badget-color;

  body {
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #f3f3f4;
    color: $text-color;

    label.required:after {
      content: " *";
      color: $red;
    }
  }

  .sf-toolbar {
    background: $label-badget-color;
  }
}
