body.forgot-password {
  .form-box {
    margin: 2em auto 0 auto;
  }
}

body.resetting {
  .form-box {
    margin: 2em auto 0 auto;
  }
}