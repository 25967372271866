header#site-header {
  nav {
    border-bottom: 4px solid $brand-primary;

    .navbar-header {
      button {
        background: $brand-primary;

        &:hover {
          background: darken($brand-primary, 5%);
        }
      }
    }

    .navbar-brand {
      @include fontSize(24);
      font-weight: 300;
      text-transform: uppercase;
      color: #fff;

      &:hover {
        color: #fff;
      }

      span {
        font-weight: 700;
      }
    }
  }
}